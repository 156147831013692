import React from "react";
import "./Certificates.css";
import { certificatesData } from "./CertificatesData";

function Certificate() {
	return (

		<div id="certificates" className="container-fluid">
			<div className="row justify-content-md-center">
				<div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
					<h1>Learnings</h1>
					<div className="headerUnderline"></div>
					<div className="certificatesmainContainer">
						<div className="row">
							{certificatesData.map((val, key) => {
								return (
									<div key={key} className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
										<div className="certificate">
											<div className="card">
												<div className="row">
													<div className="col-md-12">
														<div className="card-body">
															<div className="card-image-logo-div" style={{ backgroundColor: val.logoBg }}>
																<img id={val.id} className="card-image-logo" src={val.logo} alt={val.logoAlt} />
															</div>
															<div className="justify-content-center align-items-center card-course-title-div media">
																<div className="media-body">
																	<h5 className="card-course-title">{val.name}</h5>
																</div>
															</div>
															{/* <p className="card-text"><img id={val.id} className="card-coverImage" src={val.image} alt={val.name} /></p> */}
														</div>
													</div>
													<div className="certificate-overlay"></div>
												</div>
											</div>
											<div className="certificate-details fadeIn-bottom">
												<button className="btn btn-dark mask-btn" type="button" data-toggle="modal" data-target={"#certficate" + "-" + val.id}>
													CERTIFICATE
												</button>
											</div>
										</div>
										{/* <div className="modal-backdrop"></div> */}
										<div className="modal fade popup" id={"certficate" + "-" + val.id} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
											<div className="modal-dialog modal-lg popup-inner" role="document">
												<div className="modal-content">
													<div className="modal-header">
														<div className="col-11">
															<h3 className="text-center heading">{val.name}</h3>
														</div>
														<button className=" btn close" data-dismiss="modal" aria-label="Close">
															<a className="popup-close" href="!#">
																{" "}
															</a>
														</button>
													</div>
													<div className="modal-body">
														<div className="row">
															<div className="col-12">
																<div className="row">
																	<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
																		<div className="certificate-info">
																			<img id={val.id} className="card-img-top" src={val.image} alt={val.name} />
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Certificate;
